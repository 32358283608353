import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { WidgetAccessService } from '@core/services/widget-access/widget-access.service';

export const homePageGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const widgetAccessService = inject(WidgetAccessService);

  if (widgetAccessService.hasAccessibleWidgets()) {
    return true;
  } else {
    const path = state.url.split('/').slice(0, -1).join('/');
    const firstAccessiblePage = widgetAccessService.getFirstAccessiblePage();
    return router.createUrlTree(firstAccessiblePage ? [path, firstAccessiblePage] : ['/account-not-registered']);
  }
};
